import * as React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Contacts from '@material-ui/icons/Contacts';
import { useDispatch, useSelector } from 'react-redux';
import { BrancherDivider } from '../../Components/General/BrancherDivider';
import { BrancherSnackbar } from '../../Components/General/BrancherSnackbar';
import { Text } from '../../Components/General/Text';
import { BrancherButton, CreateButton } from '../../Components/InputFields/BrancherButton';
import { BrancherFileUpload } from '../../Components/InputFields/BrancherFileUpload';
import { BrancherTextField } from '../../Components/InputFields/BrancherTextField';
import { BrancherToggleButton } from '../../Components/InputFields/ToggleButton/BrancherToggleButton';
import { MakeOptions } from '../../Form/MakeOptions';
import { ProgramPositions } from '../../QualifyingForm/ProgramPositionOptions';
import {
  BrancherDispatch,
  SaveProfileFormData,
  UtilGetUserRoleProfile,
  UtilSaveUserRoleProfile,
} from '../../store/actions';
import { EProfileAttributes, IProfileData } from '../../store/reducers/ProfileFormReducer';
import { IStoreTypes } from '../../store/storeTypes';
import { Colors } from '../../consts/colors';
import { mobileMainNavHeight } from '../../consts/navHeights';
import { BrancherLinkText } from '../../Components/General/BrancherLinkText';
import { BrancherDialog } from '../../Components/General/BrancherDialog';
import { Profile } from './Profile';

const useStyles = makeStyles({
  parentContainer: {
    marginBottom: (props: { mobile: boolean }) =>
      props.mobile ? `calc(${mobileMainNavHeight} + 120px)` : 20,
  },
  fixedActionPane: {
    marginBottom: (props: { mobile: boolean }) => (props.mobile ? mobileMainNavHeight : 0),
    position: 'fixed',
    padding: `10px 0px 10px`,
    bottom: 0,
    marginLeft: -30,
    height: 100,
    backgroundColor: Colors.backgroundLightPurple,
    width: '-webkit-fill-available',
    zIndex: 100,
    boxShadow: `inset 0px 2px 2px -2px ${Colors.darkGrey};`,
  },
  paneButtonContainer: {
    width: '100%',
    '& > .MuiButton-root': {
      marginRight: 60,
    },
  },
});

enum EProfileFormAttributeLabels {
  PHONE_NUMBER = 'phone number',
  FIRST_NAME = 'first name',
  LAST_NAME = 'last name',
  JOB_TITLE = 'job title',
  ORGANISATION = 'organisation',
  LOCATION = 'location',
  CONTACT_EMAIL = 'contact email',
  LINKEDIN = 'linkedIn',
  PUBLIC_PROFILE = 'who can view your profile',
  PUBLIC_PROFILE_RAW = 'who can view your profile',
  // IMAGE = 'profile photo',
  // CAREER_STORY = 'tell us about yourself',
  // PREFERRED_MEETING_TIMES = 'preferred meeting times',
  // MAXIMUM_PARTNER_AMOUNT = 'maximum partner amount',
}

export const EditProfile: React.FC = () => {
  const maximumMentorAmount =
    useSelector((state: IStoreTypes) => state.user?.maximumMentorAmount) ?? 5;
  const maximumMenteeAmount =
    useSelector((state: IStoreTypes) => state.user?.maximumMenteeAmount) ?? 1;
  const profileData = useSelector((state: IStoreTypes) => state.profileForm.profile);
  const sessionPosition = useSelector((state: IStoreTypes) => state.user.sessionPosition);
  const roleLabels = useSelector((state: IStoreTypes) => state.user.roleLabels);
  const {
    companyId,
    programId,
    sessionRoleId,
    firstName,
    lastName,
    email,
    phoneNumber,
  } = useSelector((state: IStoreTypes) => state.user);
  const [profile, setProfile] = React.useState<IProfileData>({
    ...profileData,
    [EProfileAttributes.FIRST_NAME]: profileData?.[EProfileAttributes.FIRST_NAME] ?? firstName,
    [EProfileAttributes.LAST_NAME]: profileData?.[EProfileAttributes.LAST_NAME] ?? lastName,
    [EProfileAttributes.CONTACT_EMAIL]: profileData[EProfileAttributes.CONTACT_EMAIL] ?? email,
    [EProfileAttributes.PHONE_NUMBER]:
      profileData?.[EProfileAttributes.PHONE_NUMBER] ?? phoneNumber,
    [EProfileAttributes.LOCATION]:
      profileData?.[EProfileAttributes.LOCATION] ??
      Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone,
    [EProfileAttributes.MAXIMUM_PARTNER_AMOUNT]: {
      [programId]: 1,
      ...profileData?.[EProfileAttributes.MAXIMUM_PARTNER_AMOUNT],
    },
  });
  const [saving, setSaving] = React.useState<boolean>(false);
  const [gettingProfile, setGettingProfile] = React.useState<boolean>(false);
  const [validations, setValidations] = React.useState<string[]>([]);
  const [openSnackbar, setOpenSnackbar] = React.useState<boolean>(false);
  const [profileOpen, setProfileOpen] = React.useState<boolean>(false);
  const dispatch = useDispatch();
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const styles = useStyles({ mobile });

  React.useEffect(() => {
    if (!profile?.roleId) {
      setGettingProfile(true);
      BrancherDispatch(
        dispatch,
        UtilGetUserRoleProfile((profileRes) => {
          setProfile({
            ...profile,
            [EProfileAttributes.FIRST_NAME]:
              profileData[EProfileAttributes.FIRST_NAME] ?? firstName,
            [EProfileAttributes.LAST_NAME]: profileData[EProfileAttributes.LAST_NAME] ?? lastName,
            [EProfileAttributes.CONTACT_EMAIL]:
              profileData[EProfileAttributes.CONTACT_EMAIL] ?? email,
            [EProfileAttributes.PHONE_NUMBER]:
              profileData[EProfileAttributes.PHONE_NUMBER] ?? phoneNumber,
            ...profileRes,
            [EProfileAttributes.MAXIMUM_PARTNER_AMOUNT]: {
              [programId]: 1,
              ...profileRes?.[EProfileAttributes.MAXIMUM_PARTNER_AMOUNT],
            },
          });
          setGettingProfile(false);
        }),
      );
    }
  }, []);

  const returnMissingFields = (): string[] => {
    const fields: string[] = [];
    Object.keys(EProfileAttributes).forEach((attribute) => {
      if (
        !Boolean(profile?.[EProfileAttributes[attribute]]) &&
        EProfileAttributes[attribute] !== EProfileAttributes.MAXIMUM_PARTNER_AMOUNT &&
        EProfileAttributes[attribute] !== EProfileAttributes.PREFERRED_MEETING_TIMES
      ) {
        fields.push(EProfileFormAttributeLabels[attribute]);
      }
    });
    return fields;
  };

  const validateAllData = (): boolean => {
    return Boolean(
      profile?.[EProfileAttributes.CONTACT_EMAIL] &&
        profile?.[EProfileAttributes.PUBLIC_PROFILE_RAW] !== null &&
        profile?.[EProfileAttributes.FIRST_NAME] &&
        profile?.[EProfileAttributes.LAST_NAME] &&
        profile?.[EProfileAttributes.LOCATION] &&
        profile?.[EProfileAttributes.ORGANISATION] &&
        profile?.[EProfileAttributes.JOB_TITLE] &&
        profile?.[EProfileAttributes.PHONE_NUMBER],
    );
  };

  const saveProfile = () => {
    if (validateAllData()) {
      setValidations([]);
      const data: IProfileData = {
        ...profile,
        [EProfileAttributes.PUBLIC_PROFILE]:
          (profile?.[EProfileAttributes.PUBLIC_PROFILE_RAW] as unknown) !== '0',
      };
      setSaving(true);
      BrancherDispatch(
        dispatch,
        UtilSaveUserRoleProfile(data, true, (saveRes) => {
          setProfile({ ...profile, ...saveRes.data.profileData });
          setSaving(false);
          setOpenSnackbar(true);
          setProfileOpen(true);
        }),
      );
    } else {
      setValidations(returnMissingFields());
    }
  };

  const saveProfileData = (attribute: string, val: string | number) => {
    if (attribute === EProfileAttributes.MAXIMUM_PARTNER_AMOUNT) {
      setProfile({
        ...profile,
        [attribute]: { ...profile?.[attribute], [programId]: Number(val) },
      });
    } else {
      setProfile({ ...profile, [attribute]: val });
    }
  };

  const createFileName = (newProfilePic: string): string => {
    const imageFileType1 = newProfilePic.split(':image/');
    const imageFileType2 = imageFileType1[1].split(';base64');
    return `${companyId}/${programId}/profile/${sessionRoleId}-${new Date().getTime()}.${
      imageFileType2[0]
    }`;
  };

  const isMentee = sessionPosition === ProgramPositions.mentee;

  return (
    <Grid container className={styles.parentContainer}>
      <BrancherSnackbar
        controlClose={() => setOpenSnackbar(false)}
        open={openSnackbar}
        message="Profile form saved!"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      />
      {!gettingProfile ? (
        <>
          <Grid item xs={12}>
            <Text variant="sm" color="purple" fontWeight={600} marginBottom={10} display="inline">
              Upload photo
            </Text>
            <BrancherLinkText variant="xxs" display="inline" href="https://youtu.be/KhqyOer927Y" target="_blank" underline="always" marginLeft={40}>
              How to resize?
            </BrancherLinkText>
            <Box marginBottom={3}>
              <BrancherFileUpload
                value={profile?.[EProfileAttributes.IMAGE]}
                name={EProfileAttributes.IMAGE}
                updateValue={(c: string) => {
                  saveProfileData(EProfileAttributes.IMAGE, createFileName(c));
                  BrancherDispatch(dispatch, SaveProfileFormData({ newProfilePic: c }));
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} container justify="space-between">
            <Grid item xs={11} md={5}>
              <BrancherTextField
                value={profile?.[EProfileAttributes.FIRST_NAME]}
                updateValue={(a: string) => saveProfileData(EProfileAttributes.FIRST_NAME, a)}
                label="First name"
                fullWidth
                maxChars={100}
              />
            </Grid>
            <Grid item xs={11} md={5}>
              <BrancherTextField
                value={profile?.[EProfileAttributes.LAST_NAME]}
                updateValue={(a: string) => saveProfileData(EProfileAttributes.LAST_NAME, a)}
                label="Last name"
                fullWidth
                maxChars={100}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} container justify="space-between">
            <Grid item xs={11} md={5}>
              <BrancherTextField
                value={profile?.[EProfileAttributes.JOB_TITLE]}
                updateValue={(a: string) => saveProfileData(EProfileAttributes.JOB_TITLE, a)}
                label="Job title"
                fullWidth
                maxChars={100}
              />
            </Grid>
            {(maximumMenteeAmount > 1 || !isMentee) && (
              <Grid item xs={11} md={5}>
                <BrancherTextField
                  value={profile?.[EProfileAttributes.MAXIMUM_PARTNER_AMOUNT]?.[programId]}
                  updateValue={(a: string) =>
                    saveProfileData(EProfileAttributes.MAXIMUM_PARTNER_AMOUNT, a)
                  }
                  label={`Maximum number of ${
                    isMentee
                      ? roleLabels[ProgramPositions.mentor]
                      : roleLabels[ProgramPositions.mentee]
                  }s for program`}
                  fullWidth
                  max={isMentee ? maximumMenteeAmount : maximumMentorAmount}
                  min={0}
                  type="number"
                  helpText="Once your maximum capacity has been reached, you will no longer receive any further mentoring requests for this program."
                />
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} container justify="space-between">
            <Grid item xs={11} md={5}>
              <BrancherTextField
                value={profile?.[EProfileAttributes.ORGANISATION]}
                updateValue={(a: string) => saveProfileData(EProfileAttributes.ORGANISATION, a)}
                label="Organisation/Department/Division"
                fullWidth
                maxChars={100}
              />
            </Grid>
            <Grid item xs={11} md={5}>
              <BrancherTextField
                value={profile?.[EProfileAttributes.LOCATION]}
                updateValue={(a: string) => saveProfileData(EProfileAttributes.LOCATION, a)}
                label="Location"
                fullWidth
                maxChars={100}
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <BrancherDivider marginBottom={30} marginTop={30} />
          </Grid>

          <Grid item xs={12} container justify="space-between">
            <Grid item xs={11} md={5}>
              <BrancherTextField
                value={profile?.[EProfileAttributes.CONTACT_EMAIL]}
                updateValue={(a: string) => saveProfileData(EProfileAttributes.CONTACT_EMAIL, a)}
                label="Contact email"
                disabled
                fullWidth
                maxChars={100}
              />
            </Grid>
            <Grid item xs={11} md={5}>
              <BrancherTextField
                value={profile?.[EProfileAttributes.PHONE_NUMBER]}
                updateValue={(a: string) => saveProfileData(EProfileAttributes.PHONE_NUMBER, a)}
                label="Phone"
                fullWidth
                maxChars={100}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} container>
            <Grid item xs={11} md={5}>
              <BrancherTextField
                value={profile?.[EProfileAttributes.LINKEDIN]}
                updateValue={(a: string) => saveProfileData(EProfileAttributes.LINKEDIN, a)}
                label="LinkedIn"
                fullWidth
                maxChars={100}
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <BrancherDivider marginBottom={30} marginTop={30} />
          </Grid>

          <Grid item xs={12}>
            <BrancherTextField
              value={profile?.[EProfileAttributes.CAREER_STORY]}
              updateValue={(a: string) => saveProfileData(EProfileAttributes.CAREER_STORY, a)}
              label={
                isMentee
                  ? `Tell us about yourself and what you would like out of this program`
                  : `Tell us about yourself and how you can help`
              }
              placeholder="Your career story, skills, interests..."
              fullWidth
              multiline
              rowsMax={10}
              maxChars={800}
              helpText={`This is an important field that will be shown to others in this cohort.`}
            />
          </Grid>

          <Grid item xs={12}>
            <BrancherDivider marginBottom={30} marginTop={30} />
          </Grid>

          <Grid item xs={12}>
            <BrancherTextField
              value={profile?.[EProfileAttributes.PREFERRED_MEETING_TIMES]}
              updateValue={(a: string) =>
                saveProfileData(EProfileAttributes.PREFERRED_MEETING_TIMES, a)
              }
              label="What are the best times/days to reach you?"
              fullWidth
              multiline
              maxChars={400}
            />
          </Grid>

          <Grid item xs={12}>
            <BrancherDivider marginBottom={30} marginTop={30} />
          </Grid>

          <Grid item xs={12}>
            <Grid item>
              <Text variant="sm" fontWeight={600} color="purple">
                Allow others in this mentoring cohort to see your phone number, email address and
                LinkedIn profile link?
              </Text>
            </Grid>
            <Grid item container justify="space-between">
              <BrancherToggleButton
                name="publicProfileRaw"
                value={profile?.[EProfileAttributes.PUBLIC_PROFILE_RAW]}
                updateValue={(f: string) =>
                  saveProfileData(EProfileAttributes.PUBLIC_PROFILE_RAW, f)
                }
                options={MakeOptions(['No', 'Yes'])}
                halfWidth
                exclusive
              />
            </Grid>
          </Grid>

          {validations.length > 0 && (
            <Grid item>
              <Text variant="sm" color="red" fontWeight={500} marginBottom={20}>
                Please enter {validations?.join(', ')} {validations.length > 1 ? 'fields' : 'field'}{' '}to complete your profile
              </Text>
            </Grid>
          )}

          <BrancherDialog
            setClose={() => setProfileOpen(false)}
            open={profileOpen}
            fullWidth
            labelledBy="Your Profile"
            fitLargeScreen
          >
            <Profile
              profileData={profile}
              profileType={sessionPosition}
            />
          </BrancherDialog>
          <Grid item container xs={12} className={styles.fixedActionPane}>
            <Grid container justify="space-around" item xs={12} className={styles.paneButtonContainer} alignItems="center">
              {validateAllData() && (
                <Grid item>
                  <Box mr={2}>
                    <BrancherButton size="small" startIcon={<Contacts />} onClick={() => setProfileOpen(true)}>
                      View Profile
                    </BrancherButton>
                  </Box>
                </Grid>
              )}
              <Grid item>
                <CreateButton onClick={saveProfile} loading={saving} />
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid container alignItems="center" justify="center">
          <Grid item xs={12}>
            <CircularProgress color="secondary" size={64} />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
